export const people = [
  {
    id: 1,
    title: "Trustee",
    name: "Chris Jackson",
    email: "cj@stopholdingback.com",
    image: "https://i.imgur.com/KfrhNKl.png",
    website: "https://www.stephaniejacksonrecruitment.com/",
    linkedin: "https://www.linkedin.com/in/christopher-jackson-203a20140/",
    instagram: "https://www.instagram.com/imchrisjackson/",
    twitter: "",
    facebook: "",
    messenger:"",
    location: "United Kingdom",
    job: "Business Owner",
  },

  {
    id: 2,
    title: "Trustee",
    name: "Ruban Pillai",
    email: "ruban@stopholdingback.com",
    image: "https://i.imgur.com/yYI0g50.png",
    website: "https://www.rubanpillai.com/",
    linkedin: "https://www.linkedin.com/in/ruban-pillai/",
    instagram: "https://www.instagram.com/rubanpillai/",
    twitter: "https://twitter.com/RubanPillai",
    facebook: "",
    messenger:"",
    location: "United Kingdom",
    job: "Financial Services",
  },

  {
    id: 3,
    title: "Trustee",
    name: "Ayo Adesanya",
    email: "ayo@stopholdingback.com",
    image: "https://i.imgur.com/FRIs3ds.png?1",
    website: "http://www.ayoadesanya.com",
    linkedin: "https://www.linkedin.com/in/ayoadesanya/",
    instagram: "https://www.instagram.com/ayo.stopholdingback/",
    twitter: "",
    location: "United Kingdom",
    facebook: "",
    messenger:"https://m.me/ayocodes",
    job: "React Web Developer",
  },

  {
    id: 4,
    title: "Trustee",
    name: "James Redhead",
    email: "james@stopholdingback.com",
    image: "https://i.imgur.com/7506LkW.png",
    website: "",
    linkedin: "https://www.linkedin.com/in/james-redhead-b55482186/",
    instagram: "",
    twitter: "",
    facebook: "",
    messenger:"",
    location: "United Kingdom",
    job: "Charity Consultant",
  },
  {
    id: 5,
    title: "Mentor",
    name: "Callum Schofieled",
    email: "callum@stopholdingback.org",
    image: "https://imgur.com/RBIBQ6t.png",
    website: "https://www.youtube.com/channel/UCafoyMIOoFOwb45f8_RxUiQ",
    linkedin: "",
    instagram: "https://www.instagram.com/comedy_callums/",
    twitter: "https://twitter.com/comedy_callums",
    facebook: "",
    messenger:"",
    location: "United Kingdom",
    job: "Teacher & Comedian",
  },
  {
    id: 6,
    title: "Mentor",
    name: "Nick Noriega",
    email: "nick@stopholdingback.org",
    image: "https://i.imgur.com/WF7nhTW.jpg",
    website: "",
    linkedin: "",
    instagram: "https://www.instagram.com/nick.thereal.n/",
    twitter: "",
    facebook: "https://www.facebook.com/noriega.nikki95",
    messenger:"",
    location: "United States",
    job: "Mechanic",
  },
  {
    id: 7,
    title: "Mentor",
    name: "Puneet Kumar",
    email: "",
    image: "https://i.imgur.com/UoHeWO5.jpg",
    website: "https://www.taurushealthandfitness.co.uk",
    linkedin: "",
    instagram: "https://www.instagram.com/starlon12/",
    twitter: "",
    facebook: "https://www.facebook.com/pete.taurus.5",
    messenger:"https://m.me/puneetkum4r",
    location: "United Kingdom",
    job: "Entrepreneur",
  },
];
